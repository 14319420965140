<template>
	<section
		v-if="images && images.length"
		class="section section-images"
	>
		<div class="content">
			<VueSlickCarousel
				class="images-slider"
				v-bind="imageCarouselSettings"
			>
				<div
					v-for="(image, index) in images"
					:key="index"
				>
					<div
						class="images-slider__slide"
						:style="image.url | bgrDynamicImageCover"
					/>
				</div>
			</VueSlickCarousel>
		</div>
	</section>
</template>

<script>
export default {
	name: 'SectionImages',
	props: {
		images: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			imageCarouselSettings: {
				infinite: true,
				easing: 'swing',
				arrows: true,
				dots: true,
				autoplay: true,
				autoplaySpeed: 3000,
				pauseOnFocus: true,
				pauseOnHover: false,
				speed: 600,
				draggable: false,
				slidesToShow: 1,
				slidesToScroll: 1,
				adaptiveHeight: false
			}
		};
	}
};
</script>
