<template>
	<section class="section section-audience">
		<div class="content">
			<div class="audience">
				<div class="audience__header">
					<h2 class="title-section">
						Кому подойдет курс
					</h2>
					<img
						:src="'/img/pages/course/pic__audience.png' | prodSrcImage"
						alt="Картинка"
						class="audience__pic"
					>
				</div>
				<div
					class="audience__content"
					:class="{'audience__content--single': blocks.length === 1}"
				>
					<div
						v-for="block in blocks"
						:key="block.id"
						class="audience-item"
					>
						<h3 class="audience-item__title">
							{{ block.name }}
						</h3>
						<div class="audience-item__text">
							{{ block.text }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'SectionAudience',
	props: {
		blocks: {
			type: Array,
			required: true
		}
	}
};
</script>
