export function getIconName(material) {
  if (material.category === 'video' || material.category === 'audio') {
    return 'media';
  }
  if (
    material.category === 'rich_text' ||
    material.category === 'html' ||
    material.category === 'other' ||
    material.category === 'scorm_packages'
  ) {
    return 'material';
  }
  if (
    material.category === 'document' ||
    material.category === 'image' || 
    material.category === 'table' ||
    material.category === 'presentation' ||
    material.category === 'archive'
  ) {
    return 'attachment';
  }
  if (
    material.category === 'tasks' ||
    material.category === 'quizzes'
  ) {
    return 'test';
  }
  if (material.category === 'polls') {
    return 'questionary';
  } else {
    return '';
  }
}