<template>
	<div
		class="course"
		:class="{'course--finished': course.progress_percent === 100}"
	>
		<div class="course__header">
			<a
				class="course__title"
				@click="sendProposal"
			>
				{{ course.name }}
			</a>
			<div
				class="course__text"
				v-html="course.description"
			/>
		</div>
		<div class="course__footer">
			<div
				v-if="course.complete"
				class="course__status"
			>
				<img
					:src="'/img/icons/icon__plus-circle--white.svg' | prodSrcImage"
					alt="Иконка"
				>
				Курс пройден
			</div>
			<div
				v-else-if="course.progress_percent < 100 && course.progress_percent >= 0 && course.progress_percent !== null"
				class="progress-course"
			>
				<div class="progress-course__track">
					<div
						class="progress-course__line"
						:style="{'width': + course.progress_percent + '%'}"
					/>
				</div>
			</div>
			<div
				v-else
				class="course__duration"
			>
				{{ course.duration | durationFilter }}
			</div>
			<div class="course__info">
				<router-link
					:to="`/courses/${course.slug}-${course.id}`"
					target="_blank"
					class="course__link"
				>
					О курсе
				</router-link>
				<div class="course__members">
					{{ course.students_enrolled }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';

export default {
    name: 'BaseCardCourse',
    props: {
        course: {
            type: Object,
            default: null
        }
    },
    data: () => ({
        isLoading: false,
        courseCurrentLink: '#',
        isSended: false,
    }),
    asyncComputed: {
        processLink() {
            if (this.isStarted) {
                if (this.courseLink) return this.courseLink;
                else
                    return (
                        this.course &&
                        `https://lk.lift-bf.ru/course_sessions/${this.course.course_sessions[0].out_id}/`
                    );
            }

            if (this.isSended) return this.courseCurrentLink;
            else return '#';
        }
    },
    computed: {
        ...mapState('user', ['id', 'token']),
        ...mapGetters('user', {
            isLogged: 'isLoggedIn',
            user: 'userInfo',
            isRecruiter: 'isRecruiter'
        }),
        isStarted() {
            if (this.course) {
                return !!(
                    this.user &&
                    this.user.req_course_user &&
                    this.user.req_course_user.some(
                        req_course => req_course.course === this.course.id
                    )
                );
            }
            return false;
        },
        courseLink() {
            let course =
                this.user &&
                this.user.req_course_user &&
                this.user.req_course_user.find(
                    req_course => req_course.course === this.course.id
                );
            if (course) return course.course_link;
            return undefined;
        }
    },
    methods: {
        ...mapActions('tb', ['startCourse']),
        sendProposal() {
            if (!this.isRecruiter) {
                if (!this.course.course_sessions.length) {
                    this.modalOpen('courseFail', 'Курс временно не доступен');
                    return;
                }

                if (this.isStarted) {
                    this.openInNewWindow(this.processLink);
                    return;
                }

                if (!this.isStarted && this.isLogged) {
                    this.isLoading = true
                    this.startCourse({
                        user: +this.id,
                        course: this.course.id,
                        course_session: this.course.course_sessions[0].id
                    }).then(result => {
                        this.courseCurrentLink = result.course_link;
                        this.isLoading = false;
                        this.isSended = true;
                        this.processLink = this.courseCurrentLink;
                        this.openInNewWindow(this.processLink);
                    });
                }
            } else this.$router.push(`/courses/${this.course.slug}-${this.course.id}`)
        },
        openInNewWindow(url) {
            window.open(url, 'self');
        },
    }
}
</script>

<style scoped>

</style>
