<template>
	<section class="section section-certificate">
		<div class="content">
			<div class="certificate">
				<div class="certificate__content">
					<div class="title-block">
						После пройденного обучения вы получите официальный сертификат
					</div>
					<div class="certificate__text">
						Добавьте его к своему резюме
					</div>
				</div>
				<img
					:src="'/img/pages/course/pic__certificate.jpg' | prodSrcImage"
					alt="Сертификат"
					class="certificate__pic"
				>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'SectionCertificate'
};
</script>
