<template>
	<div
		class="program-item"
		:class="{'program-item--active': showMaterial }"
		@click="showMaterial = !showMaterial"
	>
		<div class="program-item__toggle">
			<div class="program-item__index">
				{{ count }}
			</div>
			<div class="program-item__title">
				{{ courseProgram.name }}
			</div>
		</div>
		<slide-up-down
			:active="showMaterial"
			:duration="durationAnimation"
		>
			<div class="program-item__content">
				<div class="program-item__text">
					{{ courseProgram.description }}
				</div>
				<div class="materials">
					<div
						v-for="el in courseProgram.materials"
						:key="el.id"
						class="material"
					>
						<div
							class="material__icon"
							:style="`/img/icons/icon-course-program-${getIconName(el)}.svg` | bgrImageCover"
						/>
						{{ el.data.name }}
					</div>
				</div>
			</div>
		</slide-up-down>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import { getIconName } from './helpers.js';
export default {
	props: {
		courseProgram: {
			type: Object,
			default: null
		},
		openAllProgram: {
			type: Boolean,
			default: false
		},
		isStartedCourse: {
			type: Boolean,
			default:false
		},
		courseSession: {
			type: Object,
			default: () => {}
		},
		completeCourse: {
			type: Boolean,
			default: false,
		},
        count: {
			type: Number,
			default: null,
		}
	},
	data() {
		return {
			showMaterial: false
		};
	},
	computed: {
		...mapGetters('user', { isLogged: 'isLoggedIn' }),
		disableMaterials() {
			return this.completeCourse && this.courseSession.deadline_type === 0;
		},
		durationAnimation() {
			if(window.innerWidth > 768) {
				return 600
			} else {
				return 0
			}
		},
		progressPercent() {
			let materialsLength = this.courseProgram.materials.length;
			let complectedMaterials = this.courseProgram.materials.filter(
				el => el.material_complete === true
			).length;
			return (100 / materialsLength) * complectedMaterials;
		},
		isFinishedTheme() {
			return this.courseProgram.materials.every(
				x => x.material_complete === true
			);
		},
		allTimeProgram() {
			return this.courseProgram.materials.reduce(function(prev, curr) {
				return prev + curr.duration;
			}, 0);
		}
	},
	watch: {
		openAllProgram() {
			this.showMaterial = this.openAllProgram;
		}
	},
	methods: {
		getIconName,
	}
};
</script>
<style lang="less" scoped>
@import "../../assets/styles/pages/course";
.active {
	.grid {
		.box {
			color: #ef722e;
		}
	}
	&::after {
		background-image: url(../../assets/img/icons/icon-select-dark-orange.svg);
		transform: rotate(180deg);
	}
}
.not-event{
	pointer-events: none;
}
</style>
