<template>
	<section class="section section-skills">
		<div class="content">
			<div class="skills">
				<div class="skills__header">
					<h2 class="title-section">
						Чему научитесь
					</h2>
					<img
						:src="'/img/pages/course/pic__skills.png' | prodSrcImage"
						alt="Картинка"
						class="skills__pic"
					>
				</div>
				<div class="skills__content">
					<div
						v-for="block in blocks"
						:key="block.id"
						class="skill"
					>
						<div class="skill__text">
							{{ block.text }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'SectionSkills',
	props: {
		blocks: {
			type: Array,
			required: true,
		},
	}
};
</script>
