<template>
	<section class="section section-features">
		<div class="features">
			<div class="features__content">
				<div
					v-for="tag in tags"
					:key="tag.id"
					class="feature"
				>
					{{ tag.name }}
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'SectionTags',
	props: {
		tags: {
			type: Array,
			required: true,
		},
	},
}
</script>
