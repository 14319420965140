<template>
	<section class="section section-video">
		<div class="content">
			<video
				class="video"
				:src="url"
				controls
			/>
		</div>
	</section>
</template>

<script>
export default {
	name: 'SectionVideo',
	props: {
		url: {
			type: String,
			required: true
		}
	}
};
</script>

<style scoped>
.video {
	margin: 0 auto;
}
</style>
